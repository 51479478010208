import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {NotificacionesServices} from "@/modules/notificaciones/services/NotificacionesServices";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: null,
        user: null,
        check: false,
        ready: false,
        notificacionesNoLeidas: 0
    },
    getters: {
        authenticated(state) {
            // return state.token && state.user
            return state.token
        },
        user(state) {
            return state.user
        },
        check(state) {
            return state.check
        },
        ready(state) {
            return state.ready
        },
        notificacionesNoLeidas(state) {
            return state.notificacionesNoLeidas
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_USER(state, data) {
            state.user = data;
        },
        SET_CHECK(state, data) {
            state.check = data;
        },
        SET_READY(state, data) {
            state.ready = data;
        },
        SET_NOTIFICACIONES_NO_LEIDAS(state, data) {
            state.notificacionesNoLeidas = data;
        }
    },
    actions: {
        async login({ dispatch }, credentials) {
            const subscription = localStorage.getItem('subscription');
            if(subscription){
                credentials.subscription = JSON.parse(subscription)
            }
            let response = await axios.post("auth/login", credentials);
            return dispatch('attempt', response.data.access_token);
        },
        async getNotificacionesNoLeidas({ commit }){
            const notificacionesNoLeidas = await NotificacionesServices.api.countNotRead()
            commit('SET_NOTIFICACIONES_NO_LEIDAS', notificacionesNoLeidas);
        },
        async attempt({ commit, state }, token) {
            if (token) {
                commit('SET_TOKEN', token);
            }

            if (!state.token) {
                commit('SET_CHECK', true);
                return
            }

            try {
                let response = await axios.get('/auth/me');
                commit('SET_USER', response.data);
            } catch (e) {
                commit('SET_TOKEN', null);
                commit('SET_USER', null);
            }
            commit('SET_CHECK', true);
        },
        logout({ commit }) {
            return axios.get('auth/logout').then(() => {
                commit('SET_TOKEN', null);
                commit('SET_USER', null);
            })
        }
    }
})
