import Axios from 'axios';
import moment from 'moment';

export const NotificacionesServices = {
    api: {
        async getAll(filter) {
            return Axios.get('notificaciones', {params: filter})
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async findByUser(filter) {
            return Axios.get('notificaciones/by-user', {params: filter})
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async countNotRead() {
            return Axios.get('notificaciones/count-not-read')
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async save(notificacion) {
            return Axios.post('notificaciones', notificacion, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async put(id, notificacion) {
            notificacion.append('_method', 'PUT');
            return Axios.post('notificaciones/' + id, notificacion, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async marcarComoLeido(id) {
            return Axios.post('notificaciones/marcar-como-leido/' + id)
        },
        async find(id) {
            return Axios.get('notificaciones/' + id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async findByUuid(uuid) {
            return Axios.get('notificaciones/uuid/' + uuid)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async delete(licencia) {
            return Axios.delete('notificaciones/' + licencia.id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
    },

    headers() {
        return [
            {value: 'Título', sortable: 'titulo'}
        ];
    },
    body() {
        const body = [
            {
                class: 'w-100 important text-bold',
                data: [{value: 'titulo'}]
            },
        ]
        return body
    },
    misNotificacionesHeaders() {
        return [
            {value: 'Título', sortable: 'titulo'},
            {value: 'Fecha', sortable: 'created_at'},
            {value: 'Firmado', sortable: 'firmado'},
        ];
    },
    misNotificacionesBody() {
        return [
            {
                class: 'w-100 important text-bold',
                data: [{value: 'titulo', label: 'Título'}]
            },
            {
                class: 'w-100 important text-bold',
                data: [{
                    value: 'created_at',
                    label: "Fecha",
                    mutator: this.normalDate
                }]
            },
            {
                class: 'w-100 important text-bold',
                data: [{
                    value: 'firmado',
                    label: "Firmado",
                    mutator: (firmado) => {
                        return firmado ? '<span>Si</span>' : '<span style="color:red">No</span>'
                    }
                }]
            },
        ]
    },
    normalDate(value) {
        let date = moment(value)
        return date.format('DD/MM/YYYY');
    },
}
