const notificacionesRoutes = [
    {
        path: '/mis-notificaciones',
        name: 'MisNotificaciones',
        component: () =>
            import ('@/modules/notificaciones/views/MisNotificaciones.vue'),
        meta: {
            requiresAuth: true,
            domain: 'MisNotificaciones',
            title: 'Mis notificaciones',
            valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"],
            blockOnDisabled: true
        }
    },
    {
        path: '/mis-notificaciones/:id',
        name: 'VerMiNotificacion',
        component: () =>
            import ('@/modules/notificaciones/views/VerMiNotificacion.vue'),
        meta: {
            requiresAuth: true,
            domain: 'MisNotificaciones',
            title: 'Mis notificaciones',
            valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"],
            blockOnDisabled: true
        }
    },
    {
        path: '/notificaciones/listar',
        name: 'ListarNotificacionesAdmin',
        component: () =>
            import ('@/modules/notificaciones/views/NotificacionesAdmin'),
        meta: {
            requiresAuth: true,
            domain: 'NotificacionesAdmin',
            title: 'Listar notificaciones',
            valid: ['RRHH', 'dev'],
            blockOnDisabled: true
        }
    },
    {
        path: '/notificaciones/nueva',
        name: 'CrearNotificacion',
        component: () =>
            import ('@/modules/notificaciones/views/CrearNotificacion'),
        meta: {
            requiresAuth: true,
            domain: 'NotificacionesAdmin',
            title: 'Crear procedimiento',
            valid: ['RRHH', 'dev'],
            blockOnDisabled: true
        }
    },
    {
        path: '/notificaciones/ver/:id',
        name: 'VerNotificacion',
        component: () =>
            import ('@/modules/notificaciones/views/VerNotificacion'),
        meta: {
            requiresAuth: true,
            domain: 'NotificacionesAdmin',
            title: 'Ver procedimiento',
            valid: ['RRHH', 'dev'],
            blockOnDisabled: true
        }
    },
// {
//     path: '/notificaciones/mostrar/:uuid',
//     name: 'MostrarNotificacion',
//     component: () =>
//         import ('@/modules/notificaciones/views/MostrarNotificacion'),
//     meta: { requiresAuth: true, domain: 'Inicio', title: 'Mostrar procedimiento', valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"] }
// },
    {
        path: '/notificaciones/editar/:id',
        name: 'EditarNotificacion',
        component: () =>
            import ('@/modules/notificaciones/views/EditarNotificacion'),
        meta: {
            requiresAuth: true,
            domain: 'NotificacionesAdmin',
            title: 'Editar procedimiento',
            valid: ['RRHH', 'dev'],
            blockOnDisabled: true
        }
    },
]

export default notificacionesRoutes
